<template>
   <v-dialog v-model="dispositionDialog" persistent max-width="850">
     <v-card v-if="dispositionRecipients">
       <v-card-title class="headline">
         {{ $t("card.memo-disposition") }}
       </v-card-title>
       <v-card-text>{{
         $t("label.to-whom-this-memo-will-be-dispositioned")
       }}</v-card-text>
       <form>
         <v-card-text class="pt-0">
          <v-row v-if="currentUser.usertype_elemen ==='DIREKTUR'">
             <v-card-text
               class="pb-0"
               v-if="
                 $v.form.selectedPersons.$error &&
                 !$v.form.selectedPersons.required
               "
             >
               <v-alert
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left"
               >
                 {{ $t("error.disposition-recipient-must-be-choosen") }}
               </v-alert>
             </v-card-text>
                     
             <v-col cols="12" sm="12" md="6">
               <div class="pl-2" v-for="item in firstColumnRecipients" :key="item.dir">
                <div style="padding: 0.5rem;border-radius: 5px; margin-bottom: 0.3rem;font-weight: 600;font-size: 0.875rem;" class="theme-bg6 white--text">
                  <span >{{ item.dir }}</span></div>
                 <v-row
                   no-gutters
                   v-for="chd in item.items"
                   :key="chd.nik"
                 >
                   <v-col cols="11" sm="11" md="11">
                     <label
                       class="avoore-checkbox path d-block"
                       v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                     >
                       <input
                         type="checkbox"
                         class="d-inline-flex"
                         v-model="form.selectedPersons"
                         :value="chd.nik"
                         :id="chd.nik"
                       />
                       <svg viewBox="0 0 21 21">
                         <path
                           d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                         ></path>
                       </svg>
                       <span
                         :for="chd.nik"
                         class="checkbox-title"
                         v-bind:class="{
                           'warning--text text-bolder':
                             chd.usertype_elemen == 'DIREKTUR',
                         }"
                         >{{ chd.nama }}
                       </span>
                       <span
                         :for="chd.nik"
                         class="checkbox-subtitle"
                         v-bind:class="{
                           'primary--text text-bolder':
                             chd.usertype_elemen == 'DIREKTUR',
                         }"
                        
                         >{{ chd.jabatan }}</span
                       >
                     </label>
                   </v-col>
                   <v-col cols="1" sm="1" md="1">
                     <span @click="togglePic(chd.nik)" style="cursor: pointer">
                       <svg
                         v-if="!isPic(chd.nik)"
                         viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                         <g
                           id="SVGRepo_tracerCarrier"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                         ></g>
                         <g id="SVGRepo_iconCarrier">
                           <path
                             d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                             fill="#bbb"
                           ></path>
                         </g>
                       </svg>
                       <svg
                         v-else
                         viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                         <g
                           id="SVGRepo_tracerCarrier"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                         ></g>
                         <g id="SVGRepo_iconCarrier">
                           <path
                             d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                             fill="#17deb6"
                           ></path>
                         </g>
                       </svg>
                     </span>
                   </v-col>
                 </v-row>
               </div>
             </v-col>
 
             <v-col cols="12" sm="12" md="6">
               <div class="pl-2" v-for="item in secondColumnRecipients" :key="item.dir">
                <div style="padding: 0.5rem;border-radius: 5px; margin-bottom: 0.3rem;font-weight: 600;font-size: 0.875rem;" class="theme-bg6 white--text">
                  <span >{{ item.dir }}</span></div>
                 <v-row
                   no-gutters
                   v-for="chd in item.items"
                   :key="chd.nik"
                 >
                   <v-col cols="11" sm="11" md="11">
                     <label
                       class="avoore-checkbox path d-block"
                       v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                     >
                       <input
                         type="checkbox"
                         class="d-inline-flex"
                         v-model="form.selectedPersons"
                         :value="chd.nik"
                         :id="chd.nik"
                       />
                       <svg viewBox="0 0 21 21">
                         <path
                           d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                         ></path>
                       </svg>
                       <span
                         :for="chd.nik"
                         class="checkbox-title"
                         v-bind:class="{
                           'warning--text text-bolder':
                             chd.usertype_elemen == 'DIREKTUR',
                         }"
                         >{{ chd.nama }}
                       </span>
                       <span
                         :for="chd.nik"
                         class="checkbox-subtitle"
                         v-bind:class="{
                           'primary--text text-bolder':
                             chd.usertype_elemen == 'DIREKTUR',
                         }"
                         
                         >{{ chd.jabatan }}</span
                       >
                     </label>
                   </v-col>
                   <v-col cols="1" sm="1" md="1">
                     <span @click="togglePic(chd.nik)" style="cursor: pointer">
                       <svg
                         v-if="!isPic(chd.nik)"
                         viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                         <g
                           id="SVGRepo_tracerCarrier"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                         ></g>
                         <g id="SVGRepo_iconCarrier">
                           <path
                             d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                             fill="#bbb"
                           ></path>
                         </g>
                       </svg>
                       <svg
                         v-else
                         viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                         <g
                           id="SVGRepo_tracerCarrier"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                         ></g>
                         <g id="SVGRepo_iconCarrier">
                           <path
                             d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                             fill="#17deb6"
                           ></path>
                         </g>
                       </svg>
                     </span>
                   </v-col>
                 </v-row>
               </div>
             </v-col>
           </v-row>
           <v-row v-else>
             <v-card-text
               class="pb-0"
               v-if="
                 $v.form.selectedPersons.$error &&
                 !$v.form.selectedPersons.required
               "
             >
               <v-alert
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left"
               >
                 {{ $t("error.disposition-recipient-must-be-choosen") }}
               </v-alert>
             </v-card-text>
                     
             <v-col cols="12" sm="12" md="6">
               <div class="pl-2">
                 <v-row
                   no-gutters
                   v-for="item in firstColumnRecipients"
                   :key="item.code"
                 >
                 <v-col cols="12" sm="12" md="12" v-if="!sameValue(item.nama_direktorat)">
                 </v-col>
                   <v-col cols="11" sm="11" md="11">
                     <label
                       class="avoore-checkbox path d-block"
                       v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                     >
                       <input
                         type="checkbox"
                         class="d-inline-flex"
                         v-model="form.selectedPersons"
                         :value="item.nik"
                         :id="item.nik"
                       />
                       <svg viewBox="0 0 21 21">
                         <path
                           d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                         ></path>
                       </svg>
                       <span
                         :for="item.nik"
                         class="checkbox-title"
                         v-bind:class="{
                           'warning--text text-bolder':
                             item.usertype_elemen == 'DIREKTUR',
                         }"
                         >{{ item.nama }}
                       </span>
                       <span
                         :for="item.nik"
                         class="checkbox-subtitle"
                         v-bind:class="{
                           'primary--text text-bolder':
                             item.usertype_elemen == 'DIREKTUR',
                         }"
                        
                         >{{ item.jabatan }}</span
                       >
                     </label>
                   </v-col>
                   <v-col cols="1" sm="1" md="1">
                     <span @click="togglePic(item.nik)" style="cursor: pointer">
                       <svg
                         v-if="!isPic(item.nik)"
                         viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                         <g
                           id="SVGRepo_tracerCarrier"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                         ></g>
                         <g id="SVGRepo_iconCarrier">
                           <path
                             d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                             fill="#bbb"
                           ></path>
                         </g>
                       </svg>
                       <svg
                         v-else
                         viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                         <g
                           id="SVGRepo_tracerCarrier"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                         ></g>
                         <g id="SVGRepo_iconCarrier">
                           <path
                             d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                             fill="#17deb6"
                           ></path>
                         </g>
                       </svg>
                     </span>
                   </v-col>
                 </v-row>
               </div>
             </v-col>
 
             <v-col cols="12" sm="12" md="6">
               <div class="pl-2">
                 <v-row
                   no-gutters
                   v-for="item in secondColumnRecipients"
                   :key="item.nik"
                 >
                   <v-col cols="11" sm="11" md="11">
                     <label
                       class="avoore-checkbox path d-block"
                       v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                     >
                       <input
                         type="checkbox"
                         class="d-inline-flex"
                         v-model="form.selectedPersons"
                         :value="item.nik"
                         :id="item.nik"
                       />
                       <svg viewBox="0 0 21 21">
                         <path
                           d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                         ></path>
                       </svg>
                       <span
                         :for="item.nik"
                         class="checkbox-title"
                         v-bind:class="{
                           'warning--text text-bolder':
                             item.usertype_elemen == 'DIREKTUR',
                         }"
                         >{{ item.nama }}
                       </span>
                       <span
                         :for="item.nik"
                         class="checkbox-subtitle"
                         v-bind:class="{
                           'primary--text text-bolder':
                             item.usertype_elemen == 'DIREKTUR',
                         }"
                         
                         >{{ item.jabatan }}</span
                       >
                     </label>
                   </v-col>
                   <v-col cols="1" sm="1" md="1" title="Pilih Sebagai PIC">
                     <v-tooltip bottom>
                       <template v-slot:activator="{ on, attrs }">
                         <span
                           v-on="on"
                           v-bind="attrs"
                           @click="togglePic(item.nik)"
                           style="cursor: pointer"
                         >
                           <svg
                             v-if="!isPic(item.nik)"
                             viewBox="0 0 24 24"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                           >
                             <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                             <g
                               id="SVGRepo_tracerCarrier"
                               stroke-linecap="round"
                               stroke-linejoin="round"
                             ></g>
                             <g id="SVGRepo_iconCarrier">
                               <path
                                 d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                                 fill="#bbb"
                               ></path>
                             </g>
                           </svg>
                           <svg
                             v-else
                             viewBox="0 0 24 24"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                           >
                             <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                             <g
                               id="SVGRepo_tracerCarrier"
                               stroke-linecap="round"
                               stroke-linejoin="round"
                             ></g>
                             <g id="SVGRepo_iconCarrier">
                               <path
                                 d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                                 fill="#17deb6"
                               ></path>
                             </g>
                           </svg>
                         </span>
                       </template>
                       <span>{{ $t("tooltip.this person is pic") }}</span>
                     </v-tooltip>
                   </v-col>
                 </v-row>
               </div>
             </v-col>
           </v-row>
           <v-card-text class="pl-2">{{
             $t("label.disposition-message-list")
           }}</v-card-text>
           <v-row>
             <v-card-text
               class="pb-0"
               v-if="
                 $v.form.selectedMessages.$error &&
                 !$v.form.selectedMessages.required
               "
             >
               <v-alert
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left"
               >
                 {{ $t("error.disposition-message-must-be-choosen") }}
               </v-alert>
             </v-card-text>
             <v-col cols="12" sm="12" md="6">
               <div class="pl-2">
                 <label
                   class="avoore-checkbox path d-block"
                   v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                   v-for="item in firstColumnMessages"
                   :key="item.id"
                 >
                   <input
                     type="checkbox"
                     class="d-inline-flex"
                     v-model="form.selectedMessages"
                     :value="item.id"
                     :id="item.id"
                   />
                   <svg viewBox="0 0 21 21">
                     <path
                       d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                     ></path>
                   </svg>
                   <span :for="item.id" class="checkbox-title">{{
                     item.message
                   }}</span>
                 </label>
               </div>
             </v-col>
             <v-col cols="12" sm="12" md="6">
               <div class="pl-2">
                 <label
                   class="avoore-checkbox path d-block"
                   v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                   v-for="item in secondColumnMessages"
                   :key="item.id"
                 >
                   <input
                     type="checkbox"
                     class="d-inline-flex"
                     v-model="form.selectedMessages"
                     :value="item.id"
                     :id="item.id"
                   />
                   <svg viewBox="0 0 21 21">
                     <path
                       d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                     ></path>
                   </svg>
                   <span v-if="parseInt(item.id) === 14" :for="item.id" class="checkbox-title" style="color: rgb(209, 59, 21);font-weight: 600;">{{
                     item.message
                   }}</span>
                   <span v-else :for="item.id" class="checkbox-title">{{
                     item.message
                   }}</span>
                 </label>
               </div>
             </v-col>
           </v-row>
 
           <v-row class="pl-2">
             <v-col cols="12" sm="12" md="6">
               <p>{{ $t("label.urgent-level") }}</p>
               <p
                 :class="urgensiClass"
                 class="text-center text-uppercase"
                 style="margin-top: 18px"
               >
                 {{ urgensiDesc }}
               </p>
               <v-slider
                 v-model="form.urgensi"
                 step="1"
                 :hint="$t('label.urgent-level')"
                 max="4"
                 min="1"
               ></v-slider>
             </v-col>
             <v-col cols="12" sm="12" md="6">
               <v-textarea
                 class="font-size-default"
                 rows="3"
                 v-model="form.comment"
                 name="comment"
                 :label="$t('label.comment')"
                 value=""
                 hint="Komentar tambahan"
               ></v-textarea>
             </v-col>
           </v-row>
         </v-card-text>
 
         <v-card-text
           class="pb-2"
           v-if="$v.form.chkAgree.$error && !$v.form.chkAgree.required"
         >
           <v-alert
             dense
             type="error"
             :icon="iconAlert"
             class="modal-alert v-alert--narrow v-alert--bubble bottom--right bottom--right-20-percent"
           >
             {{ $t("error.you-must-agree-to-process") }}
           </v-alert>
         </v-card-text>
 
         <v-card-text class="d-flex justify-end mr-2 pt-0">
           <label
             class="avoore-checkbox path d-block"
             v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
           >
             <input
               type="checkbox"
               class="d-inline-flex"
               v-model="form.chkAgree"
               :value="true"
               id="chk-agree"
             />
             <svg viewBox="0 0 21 21">
               <path
                 d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
               ></path>
             </svg>
             <span for="chk-agree" class="checkbox-title">{{
               $t("label.agree-to-process")
             }}</span>
           </label>
         </v-card-text>
       </form>
       <v-card-actions class="pb-4">
         <v-spacer></v-spacer>
 
         <v-btn outlined color="red darken-1" @click="dispositionDialog = false">
           <v-icon class="mr-2">{{ iconClose }}</v-icon>
           {{ $t("button.cancel") }}
         </v-btn>
         <v-btn outlined color="primary darken-1" @click="submit">
           <v-icon class="mr-2">{{ iconDisposition }}</v-icon>
           {{ $t("button.do-disposition") }}
         </v-btn>
       </v-card-actions>
     </v-card>
     <v-card v-if="!dispositionRecipients">
       <v-skeleton-loader
         class="mx-auto"
         max-width="100%"
         type="article, actions"
       ></v-skeleton-loader>
     </v-card>
   </v-dialog>
 </template>
 <script>
 import { mapGetters, mapActions, mapState } from "vuex";
 import {
   mdiAccountArrowRightOutline,
   mdiAlertCircleOutline,
   mdiClose,
 } from "@mdi/js";
 import { validationMixin } from "vuelidate";
 const { required } = require("vuelidate/lib/validators");
 
 export default {
   name: "disposition-modal",
   props: {
     memoId: { type: String },
   },
   data: () => ({
     iconDisposition: mdiAccountArrowRightOutline,
     iconClose: mdiClose,
     currDirektorat: '',
     form: {
       selectedPersons: [],
       selectedPic: [],
       selectedMessages: [],
       comment: "Silahkan diproses",
       urgensi: 1,
       chkAgree: 0,
     },
     urgensiDesc: "langsung",
     urgensiClass: "primary--text",
     dispositionAlert: true,
     iconAlert: mdiAlertCircleOutline,
   }),
   mixins: [validationMixin],
   validations: {
     form: {
       selectedPersons: {
         required,
       },
       selectedMessages: {
         required,
       },
       chkAgree: {
         required(val) {
           return val;
         },
       },
     },
   },
   created() {
     // this.getDataResource({ source: "disposition-recipients", bgProcess: true });
   },
   computed: {
     ...mapState("user", ["currentUser"]),
     ...mapGetters("resources", [
       "dispositionMessages",
       "dispositionRecipients",
     ]),
     dispositionDialog: {
       get() {
         return this.$store.state.inbox.dispositionDialog;
       },
       set(val) {
         this.$store.commit("inbox/SET_DISPOSITION_DIALOG", val);
       },
     },
     perColumnRecipientsRow() {
       if (!this.dispositionRecipients) return 5;
       if (this.isEventOrOdd(this.dispositionRecipients.length) == "even") {
         return this.dispositionRecipients.length / 2;
       }
       return this.dispositionRecipients.length / 2 + 1;
     },
     perColumnMessages() {
       if (!this.dispositionMessages) return 5;
       if (this.isEventOrOdd(this.dispositionMessages.length) == "even") {
         return this.dispositionMessages.length / 2;
       }
       return this.dispositionMessages.length / 2 + 1;
     },
     firstColumnRecipients() {
       if (!this.dispositionRecipients) return undefined;
       return this.dispositionRecipients.slice(0, this.perColumnRecipientsRow);
     },
     secondColumnRecipients() {
       if (!this.dispositionRecipients) return undefined;
       return this.dispositionRecipients.slice(
         this.perColumnRecipientsRow,
         this.dispositionRecipients.length
       );
     },
     firstColumnMessages() {
       if (!this.dispositionMessages) return undefined;
       return this.dispositionMessages.slice(0, this.perColumnMessages);
     },
     secondColumnMessages() {
       if (!this.dispositionMessages) return undefined;
       return this.dispositionMessages.slice(
         this.perColumnMessages,
         this.dispositionMessages.length
       );
     },
   },
   watch: {
     dispositionDialog(val) {
       if (!val) return;
       this.$v.$reset();
       this.form.selectedPersons = [];
       this.form.selectedPic = [];
       this.form.selectedMessages = [];
       this.form.chkAgree = false;
       this.setDispositionRecipients(null);
       if (this.currentUser.usertype_elemen === 'DIREKTUR') {
         this.getDataResource({
           source: "disposition-recipient-dir",
           bgProcess: true,
         });
      } else {
        this.getDataResource({
          source: "disposition-recipients",
          bgProcess: true,
        });
      }
       this.getDataResource({
         source: "disposition-messages",
         bgProcess: true,
       });
     },
     "form.urgensi"(newVal) {
       let result = "langsung";
       let resultClass = "primary--text";
       switch (newVal) {
         case 1:
           result = "langsung";
           resultClass = "primary--text";
           break;
         case 2:
           result = "biasa";
           resultClass = "success--text";
           break;
         case 3:
           result = "segera";
           resultClass = "warning--text";
           break;
         case 4:
           result = "sangat-segera";
           resultClass = "error--text";
           break;
         default:
           result = "langsung";
           resultClass = "primary--text";
           break;
       }
       this.urgensiDesc = result;
       this.urgensiClass = resultClass;
     },
     "form.selectedMessages"(newVal){
       if (newVal.includes('14')) {
         this.form.comment = 'Silahkan diarsipkan (untuk diketahui)'
       } else {
         this.form.comment = 'Silahkan diproses'
       }
     }
   },
   methods: {
     ...mapActions("resources", ["getDataResource", "setDispositionRecipients"]),
     ...mapActions("inbox", ["submitMyDisposition"]),
     ...mapActions(["setMessageBox"]),
     isPic(nik) {
       return this.form.selectedPic.includes(nik);
     },
     sameValue(val){
      const res = this.currDirektorat === val;
      // this.currDirektorat = val;
      return res;
     },
     togglePic(nik) {
       let index = this.form.selectedPic.indexOf(nik);
       let indexRecipient = this.form.selectedPersons.indexOf(nik);
       if (index !== -1) {
         this.form.selectedPic.splice(index, 1);
       } else {
        //  if (this.form.selectedPic.length >= 2) {
        //    return this.setMessageBox({
        //      snackbar: true,
        //      show: true,
        //      type: "error",
        //      title: "error.pic",
        //      message: "error.maximum person in charge is 2",
        //    });
        //  }
         if (indexRecipient !== -1) {
           this.form.selectedPic.push(nik);
         }
       }
     },
     submit() {
       this.$v.$touch();
       setTimeout(() => {
         if (this.$v.$invalid) return;
 
         //membuang pic jika tidak ada dipilih pada penerima disposisi
         this.form.selectedPic.forEach(e => {
           let indexPic = this.form.selectedPic.indexOf(e);
           let indexPerson = this.form.selectedPersons.indexOf(e);
           if (indexPerson === -1) {
             this.form.selectedPic.splice(indexPic, 1);
           }
         });
 
         let formData = {
           comment: this.form.comment,
           messages: this.form.selectedMessages.toString(),
           recipients: this.form.selectedPersons.toString(),
           pic: this.form.selectedPic.toString(),
           urgensi: this.form.urgensi,
         };
 
         /*
         kode di bawah ada param memoId
         memoId pada laman disposition adalah DispositionId
         pada laman inbox = memoId sebenarnya.
         karena modal ini berbagi pakai jadinya disulap dalam param memoId semuanya
         */
 
         
    
         this.submitMyDisposition({
           formData: formData,
           memoId: this.memoId,
           action: "dispositions",
         });
 
         this.$nextTick(() => {
           this.dispositionDialog = false;
         });
       }, 300);
     },
   },
 };
 </script>
 <style>
 .modal-alert {
   margin-bottom: 0 !important;
 }
 
 .py-0 {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
 }
 
 .px-24 {
   padding-left: 24px !important;
   padding-right: 24px !important;
 }
 </style>
 