<template>
  <section slot="pdf-content" v-if="singleMemo && singleMemo.pengirim" class="memo-print-preview">
    <section class="pdf-item" style="width: 170mm !important">
      <p
        style="
          font-size: 28px;
          text-decoration: underline;
          margin-bottom: 0px;
          text-align: center;
        "
      >
        <b>M E M O</b>
      </p>
      <hr style="width: 120px; margin: auto; margin-top: 5px" />
      <div>
        <table
          id="preview-table"
          style="
            margin: 0 auto;
            margin-top: 0px;
            width: 100%;
            line-height: 25px;
            border-collapse: collapse;
          "
        >
          <tr>
            <td style="width: 2.1cm; padding: 0.3rem; vertical-align: top">
              Dari
            </td>
            <td style="width: 0.1cm; padding: 0.3rem; vertical-align: top">
              :
            </td>
            <td
              style="
                vertical-align: top;
                padding: 0.3rem 0.3rem 0.3rem 1rem;
                vertical-align: top;
              "
            >
              <span
                v-if="
                  singleMemo.pengirim && parseInt(singleMemo.memo.is_bom) === 1
                "
                ><strong>
                  {{
                  singleMemo.pengirim.name
                    ? singleMemo.pengirim.name
                    : "[" + $t("text.not-yet-sent") + "]"
                }}</strong></span
              >
              <span
                v-if="
                  singleMemo.pengirim && parseInt(singleMemo.memo.is_bom) !== 1 &&
                  parseInt(singleMemo.memo.approver_unit_id) === 1
                "
                ><strong>{{
                  singleMemo.pengirim.bagian ? singleMemo.pengirim.bagian
                    : "[" + $t("text.not-yet-sent") + "]"
                }}</strong></span
              >
              <span
                 v-if="
                  singleMemo.pengirim && parseInt(singleMemo.memo.is_bom) !== 1 &&
                  parseInt(singleMemo.memo.approver_unit_id) !== 1
                "
                ><strong
                  ><strong>{{
                   singleMemo.pengirim.unit ? singleMemo.pengirim.unit
                      : "[" + $t("text.not-yet-sent") + "]"
                  }}</strong></strong
                ></span
              >
              <span v-if="!singleMemo.pengirim" style="margin-left: 10px"
                ><strong>N/A</strong></span
              >
            </td>
            <td
              style="
                width: 2%;
                border-left: #000 2px solid;
                padding: 0.3rem;
                vertical-align: top;
              "
            ></td>
            <td rowspan="2" style="width: 48%; vertical-align: top; padding: 0.3rem;  border-bottom: #000 3px double;">
              Kepada Yth
               <ol
                style="margin-left: 0.4cm"
                v-if="parseInt(singleMemo.memo.is_izin_prinsip) === 1 && singleMemo.penerima && singleMemo.penerima.length > 0"
              >
                <li
                  v-for="item in singleMemo.penerima"
                  v-bind:key="item.id"
                  style="line-height: 20px; display: block"
                >
                  {{ item.jabatan ? item.jabatan : item.name }}
                </li>
              </ol>
               <ol
                style="margin-left: 0.4cm"
                v-if="parseInt(singleMemo.memo.is_izin_prinsip) === 1 && singleMemo.izin_prinsip_details && singleMemo.izin_prinsip_details.length > 0 "
              >
                <li
                  v-for="item in singleMemo.izin_prinsip_details"
                  v-bind:key="item.id"
                  style="line-height: 20px; display: block"
                >
                  {{ item.name }}
                </li>
              </ol>
              <ol style="margin-left: 0.4cm" v-if="parseInt(singleMemo.memo.is_izin_prinsip) !== 1">
                <li
                  v-for="item in singleMemo.penerima"
                  v-bind:key="item.id"
                  style="line-height: 20px; display: block"
                >
                  {{ item.name }}
                </li>
              </ol>
              di- <br />
              <span style="margin-left: 2rem"
                ><b><u>T e m p a t</u></b></span
              >
            </td>
          </tr>
          <tr>
            <td
              style="
                vertical-align: bottom;
                border-bottom: #000 3px double;
                padding: 0.3rem;
              "
            >
              Tanggal
            </td>
            <td
              style="
                vertical-align: bottom;
                border-bottom: #000 3px double;
                padding: 0.3rem;
              "
            >
              :
            </td>
            <td
              style="
                vertical-align: bottom;
                border-bottom: #000 3px double;
                padding: 0.3rem;
              "
            >
              <span style="margin-left: 10px">{{
                singleMemo.memo.signed_at
                  ? mydate(singleMemo.memo.signed_at, "ldate")
                  : "[" + $t("text.not-yet-sent") + "]"
              }}</span>
            </td>
            <td
              style="
                width: 2%;
                border-left: #000 2px solid;
                border-bottom: #000 3px double;
                padding: 0.3rem;
              "
            ></td>
            
          </tr>
        </table>
        <table width="100%" style="margin-top: 0.3cm">
          <tbody>
            <tr>
              <td style="width: 2cm">Nomor</td>
              <td style="width: 0.2cm">:</td>
              <td>
                {{ singleMemo.memo.nomor ? singleMemo.memo.nomor : "N/A" }}
              </td>
            </tr>
            <tr>
              <td>Lampiran</td>
              <td>:</td>
              <td>{{ hasAttachment }}</td>
            </tr>
            <tr>
              <td>Hal</td>
              <td>:</td>
              <td>
                <b
                  ><u>{{ singleMemo.memo.hal }}</u></b
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <froalaView
      v-model="singleMemo.memo.isi"
      style="padding: 0.5cm 4.1cm 0cm 2.34cm; text-align: justify;  page-break-inside: avoid !important;"
    ></froalaView>
    <section class="pdf-item">
      <div style="padding: 0 4.1cm 0 0; text-align: justify">
        <table style="width: 100%; table-layout: fixed">
          <tr>
            <td style="width: 50%"></td>
            <td style="text-align: center">
              PT PERKEBUNAN NUSANTARA IV<br />
              <span
                v-if="
                  singleMemo.pengirim && singleMemo.memo.approver_unit_id == 1 && singleMemo.memo.is_bom != 1
                "
                >BAGIAN {{ singleMemo.pengirim.bagian }}</span
              >
              <span
                v-if="
                  singleMemo.pengirim && singleMemo.memo.is_bom == 1
                "
                > {{ parseJabatan(singleMemo.pengirim.asRecipient.id) }}</span
              >
               
               <span style="display:block;"
                v-if="
                   [2,3,4,5].includes(parseInt(singleMemo.memo.approver_unit_id)) &&
                  singleMemo.memo.is_bom != 1
                "
                >{{singleMemo.memo.pejabat_id ? 'Pjs. General Manager Distrik' :'' }}</span
              >
              <span
                v-if="
                  singleMemo.pengirim && singleMemo.memo.approver_unit_id != 1
                "
                >UNIT {{ singleMemo.pengirim.unit }}</span
              >
              <br />
              <QRCanvas :options="qrCodeOption" @updated="onUpdated" />
              <br />
              <span style="text-decoration: underline; font-weight: 700">
                {{ singleMemo.memo.approver_name }}
              </span>
              <br />
              <span
                v-if="
                  singleMemo.memo.approver_unit_id == 1 &&
                   singleMemo.is_bom == 1
                "
                >{{singleMemo.pengirim.name}}</span
              >
              <span style="display:block;"
                v-if="
                  singleMemo.memo.approver_unit_id == 1 &&
                  singleMemo.memo.is_bom != 1
                "
                >{{ isPjs(singleMemo.memo.approver_kodejabatan) }}</span
              >
             
              <span
                v-if="
                   [2,3,4,5].includes(parseInt(singleMemo.memo.approver_unit_id)) &&
                  singleMemo.memo.is_bom != 1 && !singleMemo.memo.pejabat_id 
                "
                >{{singleMemo.memo.jabatan_tetap}}</span
              >
              <span
                v-if="
                  singleMemo.memo.approver_unit_id == 1 &&
                  singleMemo.memo.is_bom == 1
                "
                >{{singleMemo.pengirim.name}}</span
              >
              <span
                v-if="
                  ![1,2,3,4,5].includes(parseInt(singleMemo.memo.approver_unit_id)) &&
                  singleMemo.memo.is_memo_direksi != 1 && !revisionPage
                "
                >{{singleMemo.memo.pejabat_id ? 'Pjs. ' :'' }}Manager</span
              >
              <span
                v-if="
                  ![1,2,3,4,5].includes(parseInt(singleMemo.memo.approver_unit_id)) &&
                  parseInt(singleMemo.memo.is_memo_direksi) !== 1 && revisionPage
                "
                >{{singleMemo.pengirim.name}}</span
              >
              <span
                v-if="
                  [2,3,4,5].includes(parseInt(singleMemo.memo.approver_unit_id)) &&
                  singleMemo.memo.is_memo_direksi != 1 && !singleMemo.memo.pejabat_id && !revisionPage
                "
                >General Manager Distrik</span
              >
              <span
                v-if="
                  singleMemo.memo.approver_unit_id == 1 &&
                  singleMemo.memo.is_memo_direksi == 1 && !revisionPage
                "
                >{{ singleMemo.pengirim.name }}</span
              >
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section class="pdf-item">
      <div style="text-align: center; page-break-inside: avoid !important">
        <table
          style="
            margin: 0 auto;
            margin-top: 30px;
            width: 100%;
            border-collapse: collapse;
          "
        >
          <tr>
            <td colspan="2" style="text-align: left">Tembusan:</td>
          </tr>
          <tr v-for="item in singleMemo.tembusan" v-bind:key="item.id">
            <td style="width: 10px">-</td>
            <td style="text-align: left">{{ item.id }}</td>
          </tr>
          <tr>
            <td style="width: 10px">-</td>
            <td style="text-align: left">Pertinggal</td>
          </tr>
        </table>
      </div>
    </section>
    <section
      class="pdf-item"
      v-if="singleMemo.memo.is_izin_prinsip == 1 && recipientSide"
    >
      <div style="padding: 0.3cm 4.1cm 2cm 0cm">
        <table style="margin: 0 auto; border-collapse: collapse; min-height:100px;" border="1">
          <tr>
            <td
              width="25%"
              style="
                text-align: center !important;
                text-transform: uppercase;
                padding-left: 0.3rem;
                padding-right: 0.3rem;
              "
            >
              Disposisi
            </td>
            <td
              width="5%"
              style="
                text-align: center !important;
                text-transform: uppercase;
                padding-left: 0.3rem;
                padding-right: 0.3rem;
              "
            >
              Setuju
            </td>
            <td
              width="5%"
              style="
                text-align: center !important;
                text-transform: uppercase;
                padding-left: 0.3rem;
                padding-right: 0.3rem;
              "
            >
              Tinjau Ulang
            </td>
            <td
              width="10%"
              style="
                text-align: center !important;
                text-transform: uppercase;
                padding-left: 0.3rem;
                padding-right: 0.3rem;
              "
            >
              Tidak Setuju
            </td>
            <td
              width="15%"
              style="
                text-align: center !important;
                text-transform: uppercase;
                padding-left: 0.3rem;
                padding-right: 0.3rem;
              "
            >
              Pendapat
            </td>
            <td
              width="5%"
              style="
                text-align: center !important;
                text-transform: uppercase;
                padding-left: 0.3rem;
                padding-right: 0.3rem;
              "
            >
              Tanda tangan
            </td>
          </tr>
          <tr v-for="item in singleMemo.penerima" v-bind:key="item.id">
            <td style="padding-left: 0.3rem; padding-right: 0.3rem">
              {{ item.nama }}
            </td>
            <td
              class="text-center"
              style="padding-left: 0.3rem; padding-right: 0.3rem"
            >
              <span v-if="parseInt(item.is_approved) === 1">Setuju</span>
            </td>
            <td
              class="text-center"
              style="padding-left: 0.3rem; padding-right: 0.3rem"
            >
              <span v-if="parseInt(item.is_approved) === 2">Tinjau Ulang</span>
            </td>
            <td
              class="text-center"
              style="padding-left: 0.3rem; padding-right: 0.3rem"
            >
              <span v-if="parseInt(item.is_approved) === 0">Tidak Setuju</span>
            </td>
            <td
              class="text-center"
              style="padding-left: 0.3rem; padding-right: 0.3rem"
            >
              {{ item.comment }}
            </td>
            <td class="text-center" style="padding: 0.3rem">
              <div
                v-if="item.comment"
                class="d-inline-flex justify-center flex-column"
                style="width: 100%; text-align: center !important"
              >
                <img
                  :src="`/assets/img/speciments/${item.nik}.png`"
                  alt="TTD"
                  style="width: 50px; text-align: center; margin: 0 auto"
                />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
  </section>
</template>

<script>
import { QRCanvas } from "qrcanvas-vue";
import { companyName, baseUrl } from "@/constants/config";
import { mdiCheckBold } from "@mdi/js";

export default {
  name: "memo-print-preview",
  props: {
    singleMemo: { type: Object },
    recipientSide: Boolean,
  },
  components: {
    QRCanvas,
  },
  data: () => ({
    companyName: companyName,
    baseUrl: baseUrl,
    iconCheck: mdiCheckBold,
    qrCodeOption: {
      cellSize: 2,
      correctLevel: "H",
      size: 80,
      data: "test",
    },
  }),
  created() {
    const image = new Image();
    image.src = window.location.origin + "/assets/img/icons/qrlogo.png";
    image.onload = () => {
      this.qrCodeOption = Object.assign({}, this.qrCodeOption, {
        logo: {
          image,
        },
      });
    };
  },
  watch: {
    singleMemo(val) {
      if (!val || !val.memo)
        return (this.qrCodeOption.data = `${baseUrl}/check/?v=undefined`);
      this.qrCodeOption.data = `${baseUrl}/check/?v=${val.memo.signature}`;
    },
  },
  mounted() {
    if (!this.singleMemo) return;
    this.$nextTick(() => {
      setTimeout(() => {
        this.qrCodeOption.data = `${baseUrl}/check/?v=${this.singleMemo.memo.signature}`;
        this.$emit("domRendered");
      }, 1000);
    });
  },
  computed: {
    hasAttachment() {
      if (!this.singleMemo) return "-";
      if (
        this.singleMemo.memo_attachments &&
        this.singleMemo.memo_attachments.length > 0
      ) {
        return "Ada";
      }
      return "-";
    },
    revisionPage(){
      return this.$route.name === 'revision.detail';
    }
  },
  methods: {
    isPjs(kodeJabatan){
      const routeSenderSide = ['terminated.detail','revision.detail','draft.detail','sent.detailmac','sent.detailmar','revoked.detail'];
      // const routeRecipientSide = ['inbox.detail','disposition-detail','permit-detail'];
      if (routeSenderSide.includes(this.$route.name) && this.singleMemo.pengirim) {
        if (kodeJabatan == 'JAB2055') return this.singleMemo.pengirim.name;
        return this.singleMemo.pengirim.asRecipient ? this.singleMemo.pengirim.asRecipient.name : '';
      }
      else {
        return this.singleMemo.memo.approver_jabatan;
      }
    },
    onUpdated() {},
    getApproval(val) {
      if (!val) return "";
      if (val == 1) return this.$t("text.agree");
      else return this.$t("text.reject");
    },
    parseJabatan(param){
      if (!param) return '';
      let jabatan = param.substring(0, 4);
      if (jabatan === 'SEVP') return 'Senior Executive Vice President'
      return param;
    }
  },
};
</script>

<style lang="scss" scoped>
.memo-print-preview {
  font-family: "Times New Roman", Times, serif !important;
  font-size: 12pt !important;
}
@media print {
  p {
    page-break-after: always !important;
  }
}

#isi-memo {
  .fr-view {
    p {
      page-break-after: always !important;
    }
  }
}
.pdf-content {
  width: 100%;
  background: #fff;

  .report-info {
    display: flex;
    padding: 20px;
    padding-bottom: 0px;

    .image-container {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      margin-right: 25px;
      border: 1px solid #dadada;

      .user-image {
        height: 100%;
        min-width: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }

    .pdf-item {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      .user-name {
        margin-bottom: 10px;
        flex-shrink: 0;
      }

      .detail-container {
        font-size: 13px;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .detail-label {
          font-weight: 600;
          margin-right: 5px;
          font-size: 13px;
        }
      }
    }
  }

  .chart-container {
    width: 100%;
    height: 320px;
    padding: 20px;
    pointer-events: none;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
</style>