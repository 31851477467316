<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard
            :height="'100%'"
            class="memo-inbox"
            v-show="!axiosLoading && !networkError && singleMemo"
          >
            <header-left
              slot="left-toolbar"
              :singleMemo="singleMemo"
              v-on:reloadMemo="reloadMemo"
              v-on:shareLink="shareLink"
              v-on:printPreview="downloadPdf"
              v-on:downloadDispoForm="downloadDispoForm"
              :heading="$t('card.inbox-detail-title')"
            />
            <header-right slot="right-toolbar" v-if="false" />

            <p v-if="!singleMemo" class="text-center py-4">
              {{ $t("label.inbox-detail-is-loading") }}
            </p>

            <v-list-item
              three-line
              v-if="singleMemo"
              class="table-responsive-container"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-avatar
                    v-on="on"
                    v-bind="attrs"
                    :class="{
                      'sender__avatar-desktop': !$vuetify.breakpoint.smAndDow,
                    }"
                  >
                    <img
                      :src="singleMemo.memo.avatar_path"
                      alt="approver"
                      v-if="singleMemo && singleMemo.memo.approver_name"
                    />
                  </v-list-item-avatar>
                </template>
                <span>{{ avatarName }}</span>
              </v-tooltip>
              <v-list-item-content class="table-responsive">
                <v-list-item-title class="headline max__width-500">
                  <span
                    style="cursor: auto;text-overflow: unset;white-space: break-spaces;"
                  >{{singleMemo && singleMemo.memo.hal ? $t("label.memo-subject") + ": " + singleMemo.memo.hal : "Hal"
                    }}
                  </span>
                  <span
                    v-if="parseInt(singleMemo.memo.urgensi) === 1"
                    class="warning--text text-smaller"
                    >({{ $t("badge.urgent") }})</span
                  >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-show="parseInt(singleMemo.memo.is_bom) === 1"
                        class="ma-2 theme-bg4"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t("badge.directors-memo") }}
                      </v-chip>
                    </template>
                    <span>{{ $t("tooltip.directors-memo") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-show="parseInt(singleMemo.memo.has_delegated) === 1"
                        class="ma-2 theme-bg"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t("badge.has-delegated") }}
                      </v-chip>
                    </template>
                    <span>{{ $t("tooltip.memo-has-delegated") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="
                          singleMemo.memo_attachments &&
                          singleMemo.memo_attachments.length > 0
                        "
                        class="att-icon"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iconAttachment }}
                      </v-icon>
                    </template>
                    <span>{{ $t("tooltip.attachment-exist") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="parseInt(singleMemo.memo.is_masuk) === 1"
                        class="att-icon"
                        color="red"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iconExternal }}
                      </v-icon>
                    </template>
                    <span>{{ $t("tooltip.incoming-letter") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="parseInt(singleMemo.memo.urgensi) === 1"
                        class="att-icon"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iconUrgent }}
                      </v-icon>
                    </template>
                    <span>{{ $t("tooltip.urgent") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="parseInt(singleMemo.memo.is_rahasia) === 1"
                        class="att-icon"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iconSecret }}
                      </v-icon>
                    </template>
                    <span>{{ $t("tooltip.secret") }}</span>
                  </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-bolder mb-4"
                  style="max-width: 600px; font-size: 1.2rem !important"
                >
                  {{
                    `${$t("label.memo-number")} : ${
                      singleMemo ? singleMemo.memo.nomor : ""
                    }`
                  }}
                  <v-btn
                    v-if="singleMemo.memo.parent_memo_id_hash"
                    small
                    depressed
                    color="primary"
                    class="ml-2"
                    @click="gotoInboxDetail"
                  >
                    <v-icon dark>{{ iconGoto }}</v-icon>
                    {{ $t("button.goto-reference") }}
                  </v-btn>
                </v-list-item-subtitle>

                <v-menu
                  bottom
                  right
                  offset-y
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-list-item-subtitle
                          v-if="
                            singleMemo && parseInt(singleMemo.memo.is_bom) === 0
                          "
                          class="
                            d-inline-flex
                            sender-info-button
                            text-bolder
                            mini-shake-infinite
                          "
                          v-on="{ ...tooltip, ...menu }"
                          v-bind="attrs"
                          style="max-width: 600px"
                          >{{ senderName
                          }}<v-icon class="">{{
                            iconInfo
                          }}</v-icon></v-list-item-subtitle
                        >

                        <v-list-item-subtitle
                          v-else
                          class="
                            sender-info-button
                            text-bolder
                            mini-shake-infinite
                          "
                          v-on="{ ...tooltip, ...menu }"
                          v-bind="attrs"
                          style="max-width: 600px"
                          >{{
                            `${singleMemo ? singleMemo.memo.nama_pengirim_memo : ''}`
                          }}<v-icon class="">{{
                            iconInfo
                          }}</v-icon></v-list-item-subtitle
                        >
                      </template>
                      <span>{{ $t("tooltip.sender-info") }}</span>
                    </v-tooltip>
                  </template>

                  <div class="popup-card" v-if="singleMemo.memo.tipe !== 'masuk'">
                    <table class="sender-info-table">
                      <tr>
                        <td v-if="singleMemo && parseInt(singleMemo.memo.is_masuk) === 1" class="desc">{{ $t("label.input by") }}</td>
                        <td v-else class="desc">{{ $t("label.signed-by") }}</td>
                        <td>:</td>
                        <td style="padding-left: 0.8rem">
                          {{ singleMemo ? singleMemo.memo.approver_name : "" }}
                        </td>
                      </tr>
                      <tr>
                        <td class="desc">{{ $t("label.revised-by") }}</td>
                        <td>:</td>
                        <td v-if="singleMemoSignerHistory">
                          <ol>
                            <li
                              v-for="item in singleMemoSignerHistory"
                              :key="item.id"
                            >
                              {{ item.nama }}
                            </li>
                          </ol>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-menu>
              </v-list-item-content>

              <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>

              <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs">
                    {{
                      singleMemo
                        ? $date(singleMemo.memo.signed_at).format("DD MMM YYYY")
                        : ""
                    }}
                  </span>
                </template>
                <span>{{
                  singleMemo
                    ? $t("tooltip.received-at") +
                      " " +
                      $date(singleMemo.memo.signed_at).fromNow()
                    : ""
                }}</span>
              </v-tooltip>
            </v-list-item>
            <froalaView
              v-if="singleMemo && singleMemo.memo.isi"
              v-model="singleMemo.memo.isi"
              class="px-4 py-3"
            ></froalaView>
            <p
              class="text-center pa-4 blue--text text--darken-2"
              v-html="
                $t(
                  'text.content-not-available-or-you-are-not-have-right-to-view'
                )
              "
              v-if="singleMemo && !singleMemo.memo.isi"
            ></p>

            <div
              class="attachment-list mb-2"
              v-if="singleMemo && singleMemo.memo_attachments.length > 0"
            >
              <span class="d-block pl-3 mt-4 text-bolder text-smaller">{{
                $t("label.attachment-list")
              }}</span>
              <ul>
                <li
                  v-for="(item, index) in singleMemo.memo_attachments"
                  v-bind:key="index"
                  v-bind:value="item.id"
                  class="file--list"
                >
                  <a href="javascript:;" class="server--file">
                    <span>{{ index + 1 }}.</span>
                    {{ item.file_name }} - {{ item.file_size | formatSize }}

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          large
                          class="ml-2"
                          color="blue"
                          v-on:click="downloadAttachment(item)"
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon>{{ iconDownload }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("tooltip.download-this-file") }}</span>
                    </v-tooltip>
                  </a>
                </li>
              </ul>
            </div>
            <v-card-text class="mb-4">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  v-if="singleMemo && singleMemo.penerima"
                >
                  <h4 class="mt-3 mb-3">{{ $t("text.memo-recipients") }}</h4>

                  <v-chip
                    v-for="item in singleMemo.penerima"
                    :key="item.id"
                    class="mr-1 mb-1 recipient__chip"
                  >
                    {{ item.nama }}
                  </v-chip>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  v-bind:class="{
                    'cc__border-left': !$vuetify.breakpoint.smAndDown,
                  }"
                  v-if="
                    singleMemo &&
                    singleMemo.tembusan &&
                    singleMemo.tembusan.length > 0
                  "
                >
                  <h4 class="mt-3 mb-3">{{ $t("text.memo-carbon-copy") }}</h4>
                  <v-chip
                    v-for="item in singleMemo.tembusan"
                    :key="item.id"
                    class="mr-1 mb-1 recipient__chip"
                  >
                    {{ item.nama }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>

            <hr class="card-footer" v-if="singleMemo && userIsDirPjp" />
            <v-card
              v-if="singleMemo"
              elevation="0"
              class="
                mt-2
                d-flex
                justify-end
                no-box-shadow no-border
                v-sheet--outlined
              "
            >
              <v-tooltip bottom v-if="singleMemo">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    class="ma-2"
                    outlined
                    color="primary"
                    @click="downloadPdf()"
                  >
                    <v-icon>{{ iconPrint }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.print-preview") }}</span>
              </v-tooltip>

              <v-btn
                v-if="singleMemo && !hasDelegated && userIsDirPjp"
                class="ma-2"
                outlined
                color="primary"
                @click="setDispositionDialog(true)"
              >
                <v-icon>{{ iconTransfer }}</v-icon>
                {{ $t("button.do-disposition") }}
              </v-btn>

              <v-btn
                v-else-if="singleMemo && hasDelegated"
                class="ma-2"
                outlined
                color="primary"
                @click="setDispositionHistoryDialog(true)"
              >
                <v-icon class="mr-2">{{ iconHistory }}</v-icon>
                {{ $t("button.show-disposition-history") }}
              </v-btn>
            </v-card>
          </BaseCard>

          <v-card
            v-if="(networkError || axiosLoading) && !singleMemo"
            class="pa-4"
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="100%"
              type="table-heading,divider, list-item-avatar-two-line, article: heading, paragraph, divider, actions"
            ></v-skeleton-loader>
          </v-card>
          <v-card
            v-if="(networkError || !axiosLoading) && !singleMemo"
            style="min-height: 85vh"
          >
            <v-card-text>
              <p class="text-center py-5">
                {{ $t("text.inbox-detail-is-empty") }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-flex>

    <disposition-modal :memoId="memoId" />
    <broadcast-modal :memoId="memoId" :isSecretMemo="isSecretMemo" />
    <disposition-history-modal
      :memoId="memoId"
      :dispositionId="dispositionId"
    />
    <memo-validation-modal :singleMemo="singleMemo"></memo-validation-modal>

    <vue-html2pdf
      :show-layout="controlValue.showLayout"
      :float-layout="controlValue.floatLayout"
      :enable-download="controlValue.enableDownload"
      :preview-modal="controlValue.previewModal"
      :filename="controlValue.filename"
      :paginate-elements-by-height="controlValue.paginateElementsByHeight"
      :pdf-quality="controlValue.pdfQuality"
      :pdf-format="controlValue.pdfFormat"
      :pdf-orientation="controlValue.pdfOrientation"
      :pdf-content-width="controlValue.pdfContentWidth"
      :manual-pagination="controlValue.manualPagination"
      :html-to-pdf-options="htmlToPdfOptions"
      @progress="onProgress($event)"
      @startPagination="startPagination()"
      @hasPaginated="hasPaginated()"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <memo-print-preview
        @domRendered="domRendered()"
        v-bind:singleMemo="singleMemo"
        slot="pdf-content"
      />
    </vue-html2pdf>

    <v-overlay v-bind:value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { http } from "@/http/http";
import dispositionModal from "@/components/commonComponents/DispositionModal";
import dispositionHistoryModal from "@/components/commonComponents/DispositionHistoryModal";
import broadcastModal from "@/components/commonComponents/BroadcastModal";
import memoValidationModal from "@/components/commonComponents/MemoValidationModal";
import MemoPrintPreview from "@/components/commonComponents/MemoPrintPreview.vue";
import VueHtml2pdf from "vue-html2pdf";
import { mapFields } from "vuex-map-fields";

import {
  mdiAttachment,
  mdiTransfer,
  mdiClockFast,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiAlertCircleOutline,
  mdiInformationOutline,
  mdiCloudDownload,
  mdiDatabaseClockOutline,
  mdiPrinter,
  mdiLinkVariant,
  mdiInboxArrowDown,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
    dispositionModal,
    dispositionHistoryModal,
    broadcastModal,
    memoValidationModal,
    VueHtml2pdf,
    MemoPrintPreview,
  },
  data: () => ({
    DispositionModalDummyAvatar: "https://i.pravatar.cc/100",
    iconAttachment: mdiAttachment,
    iconUrgent: mdiClockFast,
    iconTransfer: mdiTransfer,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconAlert: mdiAlertCircleOutline,
    iconInfo: mdiInformationOutline,
    iconHistory: mdiDatabaseClockOutline,
    iconDownload: mdiCloudDownload,
    iconPrint: mdiPrinter,
    iconGoto: mdiLinkVariant,
    iconExternal: mdiInboxArrowDown,
    overlay: false,
  }),
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("inbox", [
      "singleMemo",
      "axiosLoading",
      "singleMemoHistory",
      "inboxAlert",
      "inboxAlertMessage",
      "delegationHistory",
      "singleMemoSignerHistory",
    ]),
    ...mapState("user", ["currentUser"]),
    ...mapFields("pdfgenerator", ["controlValue"]),
    inboxAlert: {
      get() {
        mdiClockFast;
        return this.$store.state.inbox.inboxAlert;
      },
      set(val) {
        this.$store.commit("inbox/SET_INBOX_ALERT", val);
      },
    },
    htmlToPdfOptions() {
      return {
        margin: 2,
        pagebreak: {
          mode: ["avoid-all", "css", "legacy"],
        },
        filename: this.singleMemo
          ? this.strCut(this.singleMemo.memo.hal, 100) + ".pdf"
          : "undefined.pdf",
        image: {
          type: "jpeg",
          quality: 2,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: "cm",
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      };
    },
    userIsDirPjp() {
      if (!this.currentUser) return false;
      return ["DIREKTUR", "PJP", "PJP_KANPUS"].includes(
        this.currentUser.usertype_elemen
      );
    },
    hasDelegated() {
      if (!this.singleMemo) return false;
      return parseInt(this.singleMemo.memo.has_delegation) === 1;
    },
    memoId() {
      if (!this.singleMemo) return null;
      return this.singleMemo.memo.id_hash;
    },
    isSecretMemo() {
      if (!this.singleMemo) return false;
      return this.singleMemo.memo.is_rahasia == 1;
    },
    dispositionId() {
      if (!this.singleMemo || !this.singleMemo.disposition) return null;
      return this.singleMemo.disposition;
    },
    senderName() {
      if (!this.singleMemo) return "";
      return this.singleMemo.memo.nama_pengirim_memo
      // return this.singleMemo.memo.approver_name;
    },
    avatarName() {
      if (!this.singleMemo) return "";
      if (parseInt(this.singleMemo.memo.is_masuk) === 1)
        return (
          this.$t("text.input to system by") +
          ": " +
          this.singleMemo.memo.approver_name
        );
      return this.singleMemo.memo.approver_name;
    },
  },
  created() {
    this.reloadMemo();
    // this.getDisposition({
    //   bgProcess: true,
    //   type: "mydelegation",
    //   memoId: this.$route.params.id,
    // });
  },
  watch: {
    inboxAlert(val) {
      if (!val) return;
      setTimeout(() => {
        this.setInboxAlert(false);
      }, 5000);
    },
  },
  filters: {
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
  methods: {
    ...mapActions("inbox", [
      "loadInboxDetail",
      "setAxiosLoading",
      "setInboxAlert",
      "setDispositionDialog",
      "setDispositionHistoryDialog",
      "getDisposition",
      "loadSignerHistory",
    ]),
    // ...mapActions("disposition",["getDispositionTrack"]),
    reloadMemo() {
      this.loadInboxDetail(this.$route.params.id);
      // this.getDispositionTrack(this.$route.params.id);
      this.loadSignerHistory(this.$route.params.id);
    },
    gotoInboxDetail() {
      //if me as creator separate from me as reviser
      if (!this.currentUser) return;
      let route = this.$router.resolve({
        path: `/memo/sent/${this.singleMemo.memo.parent_memo_id_hash}/mar`,
      });
      if (this.currentUser.nik == this.singleMemo.memo.creator_nik)
        route = this.$router.resolve({
          path: `/memo/sent/${this.singleMemo.memo.parent_memo_id_hash}/mac`,
        });
      window.open(route.href, "_blank");

      // return this.$router.push({
      //   name: "sent.detailmac",
      //   params: { id: this.singleMemo.memo.parent_memo_id_hash },
      // });
      // return this.$router.push({
      //   name: "sent.detailmar",
      //   params: { id: this.singleMemo.memo.parent_memo_id_hash },
      // });
    },
    downloadAttachment(file) {
      if (!file) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };

      this.updateOverlay(true);

      http
        .get(`/memo/${file.memo_id}/attachments/${file.id_hash}`, options)
        .then((response) => {
          if (response.status === 500) return this.updateOverlay(false);
          if (response) {
            let fileURL = window.URL.createObjectURL(new Blob([response]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", file.file_name);
            document.body.appendChild(fileLink);

            this.updateOverlay(false);
            fileLink.click();
          }
        });
    },
    async downloadPdf() {
      if (
        this.currentUser.usertype_elemen === "SEKRETARIS" &&
        this.isSecretMemo
      ) {
        if (this.userIsSekretaris) {
          return this.$dialog.notify.error(
            this.$t("error.secret-memo-cannot-be-print-preview"),
            {
              position: "top-left",
              timeout: 3000,
            }
          );
        }
      }
     
      this.updateOverlay(true);
      try {
        const response = await http.get(
          `/memo/myrevision/${this.$route.params.id}/getpdf`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
            responseType: "blob",
          }
        )
        var file = new Blob([response], { type: 'application/pdf' });
        var url = URL.createObjectURL(file);
        window.open(url, '', 'width=800,height=768');
      }
      catch (error) {
        console.error(error);
        this.updateOverlay(false);
      }
      finally {
        this.updateOverlay(false);
      }
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert("pdf-quality value should only be 0 - 2");
        this.controlValue.pdfQuality = 2;
        return false;
      }
      if (!this.controlValue.paginateElementsByHeight) {
        alert("paginate-elements-by-height value cannot be empty");
        this.controlValue.paginateElementsByHeight = 1400;
        return false;
      }
      const paperSizes = [
        "a0",
        "a1",
        "a2",
        "a3",
        "a4",
        "letter",
        "legal",
        "a5",
        "a6",
        "a7",
        "a8",
        "a9",
        "a10",
      ];
      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`);
        this.controlValue.pdfFormat = "a4";
        return false;
      }
      if (!this.controlValue.pdfOrientation) {
        alert("pdf-orientation value cannot be empty");
        this.controlValue.pdfOrientation = "portrait";
        return false;
      }
      if (!this.controlValue.pdfContentWidth) {
        alert("pdf-content-width value cannot be empty");
        this.controlValue.pdfContentWidth = "800px";
        return false;
      }
      return true;
    },
    onProgress(progress) {
      this.progress = progress;
      console.info(`PDF generation progress: ${progress}%`);
    },
    startPagination() {
      // console.log(`PDF has started pagination`);
    },
    hasPaginated() {
      // console.log(`PDF has been paginated`);
    },
    async beforeDownload() {
      //param{ html2pdf, options, pdfContent }
      // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
      // 	const totalPages = pdf.internal.getNumberOfPages()
      // 	for (let i = 1; i <= totalPages; i++) {
      // 		pdf.setPage(i)
      // 		pdf.setFontSize(10)
      // 		pdf.setTextColor(150)
      // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
      // 	}
      // }).save()
    },
    hasDownloaded() {
      this.pdfDownloaded = true;
      this.updateOverlay(false);
    },
    domRendered() {
      this.contentRendered = true;
    },
    onBlobGenerate() {},
    updateOverlay(val) {
      this.overlay = val;
    },
    shareLink() {
      if (!this.singleMemo) return;
      this.$copyText(
        `https://elemen4.ptpn4.co.id/link/${this.singleMemo.memo.id_hash}`
      ).then(() => {
        this.$dialog.notify.info(
          this.$t("msgbox.you-just-copied-the-memo-message-to-clipboard"),
          {
            position: "top-left",
            timeout: 5000,
          }
        );
      });
    },
    async downloadDispoForm() {
      let params = {
        memoId: this.singleMemo.memo.id_hash,
        dispositionId: this.singleMemo.disposition,
      };
      return new Promise((resolve) => {
        http
          .get(
            `/memo/mydisposition/${params.memoId}/getform/${params.dispositionId}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                mode: "no-cors",
              },
              responseType: "blob",
            }
          )
          .then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "disposition_" + params.dispositionId + ".pdf"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          });
        return resolve;
      });
    },
  },
};
</script>
<style>
.sender__avatar-desktop {
  height: 75px !important;
  width: 75px !important;
}
</style>